<template>
  <div class="modalBase">
    <foo-modal
        :adaptive="adaptive"
        :clickToClose="clickToClose ? clickToClose : false"
        :delay="delay ? delay : 0"
        :height="height ? height : 1000"
        :minHeight="minHeight ? minHeight : 1300"
        :name="name"
        :pivotY="0"
        :scrollable="scrollable ? scrollable : false"
        :style="`padding: ${padding}`"
        :width="width ? width : 600"
        @before-open="beforeOpen"
    >
      <transition :name="transition">
        <div
            class="modalBase--container"
            v-show="show"
        >
          <div class="modalBase--header">
            <slot name="header"></slot>
            <div
                @click="beforeClose"
                class="modalBase--header--close"
            >
              <span><i class="el-icon-close"></i></span>
            </div>
          </div>
          <div class="modalBase--body">
            <slot name="body"></slot>
          </div>
          <div class="modalBase--footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </transition>
      <div
          element-loading-spinner="el-icon-loading"
          style="margin-top: 40vh"
          v-loading="true"
          v-show="!show"
      />
    </foo-modal>
  </div>

</template>

<script>
//https://github.com/euvl/vue-js-modal documentation for modal
import Vue from 'vue';
import VModal from 'vue-js-modal';

Vue.use(VModal, {componentName: 'foo-modal'});

export default {
  name: 'BaseModal',
  props: {
    padding: {
      type: String,
      default: '60px 0',
    },
    show: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    adaptive: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'fade',
    },
    delay: {
      type: Number,
      default: 300,
    },
    minHeight: {
      type: Number,
      default: 0,
    },
    height: {
      type: [String, Number],
    },
    width: {
      type: String,
    },
    scrollable: {
      type: Boolean,
    },
    clickToClose: {
      type: Boolean,
    },
  },
  methods: {
    beforeOpen() {
      this.$emit('beforeOpen');
    },
    beforeClose(event) {
      this.$emit('beforeClose', event);
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.modalBase {
  .v--modal {
    /*top: 60px !important;*/
    /*padding: 60px 0 60px 0;*/
    background-color: transparent;
    box-shadow: none;
  }

  .v--modal-overlay {
    z-index: 2000;
  }

  .v--modal-overlay.scrollable .v--modal-box {
    position: sticky;
    margin-bottom: 0;
  }
}

.swal2-container {
  z-index: 2100;
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.modalBase--container {
  background-color: transparent;
  overflow: hidden;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  box-shadow: none;
}

.modalBase--header {
  padding: 10px 15px;
  min-height: 60px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, #161D26 20%, #2D3540 60%);
}

.modalBase--footer {
  background-color: #fff;
}

.modalBase--body {
  background-color: #fff;
}

.modalBase--header--close {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}
</style>
